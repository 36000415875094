import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Head from '~components/Head'
import Banner from '~components/Banner'
import { use as useSection } from '~components/sections'
import FaqAccordions from '~components/FaqAccordions'
import Sidebar from '~components/Sidebar'

export default class Destination extends Component {
  componentDidMount () {
    const { country } = this.props.data.contentfulDestination
    this.setContentGrouping(country.name, this.isFeatured())
  }

  componentWillUnmount () {
    this.setContentGrouping('', '')
  }

  setContentGrouping (country, featured) {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const data = this.props.data.contentfulDestination
      window.dataLayer.push({
        destinationLabel: country !== '' ? data.title : '',
        destinationCountryLabel: country,
        destinationFeatured: featured
      })
    }
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('config', 'UA-9956351-3', {
        content_group1: country,
        content_group2: featured
      })
    }
  }

  isFeatured () {
    const { featured } = this.props.data.contentfulDestination
    return featured ? 'Yes' : 'No'
  }

  render () {
    const data = this.props.data.contentfulDestination
    const vaccinationRules = this.props.data.vaccinationRules
    let position = 0

    return (
      <>
        <Head
          title={data.title}
          description={data.metaDescription}
          image={data.featuredImage}
          location={this.props.location}
        />

        <Banner
          title={data.title}
          subtitle={data.subtitle}
          image={data.featuredImage}
          sidebar={true}
        />

        <div className="section section-grid">
          <div className="grid-container">
            <div className="grid-x grid-margin-x grid-small-center grid-large-row-reverse grid-spacing">
              <div className="cell medium-8 large-5 xlarge-4">
                <Sidebar
                  destination={data.title}
                  country={data.country}
                  alertLevel={data.alertLevel}
                  pricePrefix={data.pricePrefix}
                  price={data.price}
                  priceSuffix={data.priceSuffix}
                  url={data.url}
                  bookTestLink={data.bookTestLink}
                  bookTestText={data.bookTestText}
                  vaccinationRules={vaccinationRules}
                  flights={[{
                    title: data.airline1 && data.airline1.title,
                    logo: data.airline1 && data.airline1.file,
                    link: data.airlineLink1 && data.airlineLink1.airlineLink1
                  },
                  {
                    title: data.airline2 && data.airline2.title,
                    logo: data.airline2 && data.airline2.file,
                    link: data.airlineLink2 && data.airlineLink2.airlineLink2
                  },
                  {
                    title: data.airline3 && data.airline3.title,
                    logo: data.airline3 && data.airline3.file,
                    link: data.airlineLink3 && data.airlineLink3.airlineLink3
                  },
                  {
                    title: data.airline4 && data.airline4.title,
                    logo: data.airline4 && data.airline4.file,
                    link: data.airlineLink4 && data.airlineLink4.airlineLink4
                  },
                  {
                    title: data.airline5 && data.airline5.title,
                    logo: data.airline5 && data.airline5.file,
                    link: data.airlineLink5 && data.airlineLink5.airlineLink5
                  }]}
                />
              </div>

              <div className="cell large-7 xlarge-8">
                <FaqAccordions accordions={data.faqAccordions} />
              </div>
            </div>
          </div>
        </div>

        {data.sections && data.sections.length > 0 && data.sections.map((section, index) => {
          const Component = useSection(section.__typename)

          if (section.__typename === 'ContentfulSectionSignpost') {
            section.position = position
            ++position
          } else {
            position = index
          }

          return Component && (
            <Component
              key={index}
              data={section}
              position={position}
              vaccinationRules={vaccinationRules}
            />
          )
        })}
      </>
    )
  }
}

Destination.propTypes = {
  data: PropTypes.object,
  id: PropTypes.number,
  location: PropTypes.object
}

export const query = graphql`
  query($id: String!) {
    vaccinationRules: contentfulVaccinationRules {
      outboundTestingTitle
      outboundTestingText
      outboundTestingLink
      outboundQuarantineTitle
      outboundQuarantineText
      outboundQuarantineLink
    }
    contentfulDestination(id: {eq: $id}) {
      title
      subtitle
      featuredImage {
        id
        title
        desktop: fixed(width: 1600, height: 550, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        tablet: fixed(width: 1100, height: 378, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        thumb: fixed(width: 414, height: 568, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        openGraph: fixed(width: 1200, height: 627, quality: 80, resizingBehavior: CROP, cropFocus: CENTER) {
          src
        }
      }
      featured
      alertLevel 
      country {
        id
        name
        countryCode
        toggleVaccinationStatus
        outboundTestingTitle
        outboundTestingText
        outboundTestingLink
        outboundQuarantineTitle
        outboundQuarantineText
        outboundQuarantineLink
        inboundTestingTitle
        inboundTestingText
        inboundTestingLink
        inboundQuarantineTitle
        inboundQuarantineText
        inboundQuarantineLink
        vaccinatedTestingTitle
        vaccinatedTestingText
        vaccinatedTestingLink
        vaccinatedQuarantineTitle
        vaccinatedQuarantineText
        vaccinatedQuarantineLink
      }
      url
      bookTestText
      bookTestLink
      faqAccordions {
        title
        items {
          id
          icon {
            file {
              fileName
              url  
            }
            title
          }
          question
          answer {
            json
          }
          link
        }
      }
      airline1 {
        title
        file {
          url
          fileName
        }
      }
      airlineLink1 {
        airlineLink1
      }
      airline2 {
        title
        file {
          url
          fileName
        }
      }
      airlineLink2 {
        airlineLink2
      }
      airline3 {
        title
        file {
          url
          fileName
        }
      }
      airlineLink3 {
        airlineLink3
      }
      airline4 {
        title
        file {
          url
          fileName
        }
      }
      airlineLink4 {
        airlineLink4
      }
      airline5 {
        title
        file {
          url
          fileName
        }
      }
      airlineLink5 {
        airlineLink5
      }
      sections {
        ... on ContentfulSectionCta {
          ...Cta
        }
        ... on ContentfulSectionSignpost {
          ...Signpost
        }
        ... on ContentfulSectionDestinations {
          ...Destinations
        }
      }
      metaDescription
    }
  }
`
