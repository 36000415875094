import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RichText from '~components/RichText'
import Link from '~components/Link'
import AnimateHeight from 'react-animate-height'
import { isScreenSize } from '~scripts/helpers'
import styles from '~styles/components/faqs.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class Faqs extends Component {
  constructor () {
    super()
    this.ref = React.createRef()
    this.currentYPos = 0
  }

  getHeaderOffset () {
    return isScreenSize('large') ? 78 : 64
  }

  handleScrollTo (dims) {
    if (dims.newHeight > 0) {
      this.currentYPos = window.pageYOffset
      const offset = Math.abs(this.ref.current.offsetTop - 16)
      const headerOffset = this.getHeaderOffset()
      const direction = offset >= this.currentYPos ? 'down' : 'up'
      const pos = direction === 'up' ? Math.abs(offset - headerOffset) : offset

      window.scrollTo({
        top: pos,
        behavior: 'smooth'
      })
    }
  }

  render () {
    const height = this.props.active ? 'auto' : 0

    return (
      <section
        ref={this.ref}
        className={classNames(styles.wrapper, this.props.className, { [styles.open]: this.props.active })}
      >
        <header className={styles.header}>
          <h2 className={classNames(styles.title)}>
            <button type="button" className={styles.button} onClick={this.props.onClick}>
              {this.props.title}
            </button>
          </h2>
        </header>

        <AnimateHeight
          duration={300}
          height={height}
          onAnimationEnd={(dims) => this.handleScrollTo(dims)}
        >
          <div className={classNames(styles.items, 'grid-x grid-margin-x')}>
            {[...this.props.items].map((item, index) => (
              <div className={classNames(styles.item, 'cell medium-6 large-12 xlarge-6')} key={index}>
                <span className={styles.icon}>
                  <img className={styles.sprite} src={item.icon.file.url} alt={item.icon.title} />
                </span>

                <span className={styles.question}>
                  {item.question}
                </span>

                <div className={classNames(editorStyles.editor, styles.answer)}>
                  <RichText json={item.answer.json} />
                </div>

                {item.link && (
                  <Link href={item.link} className={styles.link}>
                    {item.link}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </AnimateHeight>
      </section>
    )
  }
}

Faqs.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.shape.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.shape.isRequired,
    link: PropTypes.string
  })).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}
