/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '~components/Link'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import styles from '~styles/components/cases.module.scss'

export default class Cases extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      CasesPerHundredThousand: 'Loading',
      ThisWeek: 0,
      LastWeek: 0
    }
  }

  componentDidMount () {
    const countryCode = this.props.countryCode

    // - Now update the covid stats
    fetch(`https://corona-api.com/countries/${countryCode}?include=timeline`)
      .then(res => res.json())
      .then(
        (result) => {
          // -- Check to see if API returned any data to show
          if ('data' in result) {
            var ThisWeek = 0
            var LastWeek = 0

            // -- Check to see if we have enoth data to do an comparison
            if (result.data.timeline.length >= 14) {
              // -- Adding up last and this week.
              result.data.timeline.slice(0, 7).forEach((element) => { ThisWeek = +element.active })
              result.data.timeline.slice(7, 14).forEach((element) => { LastWeek = +element.active })

              this.setState({
                isLoaded: true,
                ThisWeek: ThisWeek,
                LastWeek: LastWeek,
                CasesPerHundredThousand: result.data.latest_data.calculated.cases_per_million_population / 10
              })
            }
          }
        },

        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: false,
            error
          })
        }
      )
  }

  render () {
    const { isLoaded, CasesPerHundredThousand, ThisWeek, LastWeek } = this.state
    // -- Get the last 7 days

    // Did we get result? check isLoaded
    if (isLoaded) {
      return (
        <div className={classNames(styles.wrapper, this.props.className)}>
          <span className={styles.title}>
            COVID cases this week
          </span>

          <div className={styles.stats}>
            <div className={styles.stat}>
              <span className={classNames(styles.count, styles.countOrange)}>
                {CasesPerHundredThousand}
              </span>

              per 100,000 people
            </div>

            <div className={styles.stat}>
              <span className={classNames(styles.count, (ThisWeek > LastWeek ? styles.countRed : styles.countGreen))}>
                <span className={classNames('icon', styles.icon, (ThisWeek > LastWeek ? styles.iconUp : styles.iconDown))} aria-hidden="true">
                  <Sprite name="chevron" fill={true} />
                </span>

                {Math.abs(parseFloat((ThisWeek - LastWeek) / LastWeek * 100).toFixed(2))}
                %
              </span>

              from last week
            </div>
          </div>

          <Link className={styles.link} href="https://about-corona.net/dashboard">
            Source

            <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
              <Sprite name="external" />
            </span>
          </Link>
        </div>
      )
    } else {
      // -- Nope, Dont show the panel
      return null
    }
  }
}

Cases.propTypes = {
  className: PropTypes.string,
  countryCode: PropTypes.string
}
