/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Link from '~components/Link'
import Pill from '~components/Pill'
import { convertToTitleCase } from '~scripts/helpers'
import VaccineStatus from '~components/VaccineStatus'
import { connect } from 'react-redux'
import styles from '~styles/components/info.module.scss'
import buttonStyles from '~styles/components/button.module.scss'

class Info extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  switchTab (index, e) {
    this.setState({
      activeTab: index
    })
  }

  getFilteredFlights () {
    const { flights } = this.props

    return flights.filter((item) => (
      item.url !== null && item.logo !== null
    ))
  }

  isVaccinated () {
    const { vaccinated, country } = this.props
    const { toggleVaccinationStatus } = country
    return vaccinated && toggleVaccinationStatus
  }

  render () {
    const { country, vaccinationRules, alertLevel } = this.props
    const { name, toggleVaccinationStatus } = this.props.country
    const flights = this.getFilteredFlights()

    return (
      <div className={classNames(styles.wrapper, this.props.className)}>
        <div className={styles.header}>
          <span className={styles.heading}>
            {name}
          </span>

          <Pill
            status={alertLevel}
            highlight={true}
            className={styles.pill}
          />
        </div>

        <div className={styles.inner}>
          <div className={styles.items}>
            <div className={classNames(styles.item, styles.itemTabs)}>
              <div className={styles.tabs}>
                <button
                  type="button"
                  aria-label="Departing from BHX"
                  onClick={() => this.switchTab(0)}
                  disabled={this.state.activeTab === 0}
                  className={classNames(
                    styles.tab,
                    buttonStyles.button,
                    { [buttonStyles.grey]: this.state.activeTab !== 0 },
                    { [styles.tabActive]: this.state.activeTab === 0 }
                  )}
                >
                  Departing from BHX
                </button>

                <button
                  type="button"
                  aria-label="Arriving back into BHX"
                  onClick={() => this.switchTab(1)}
                  disabled={this.state.activeTab === 1}
                  className={classNames(
                    styles.tab,
                    buttonStyles.button,
                    { [buttonStyles.grey]: this.state.activeTab !== 1 },
                    { [styles.tabActive]: this.state.activeTab === 1 }
                  )}
                >
                  Arriving back into BHX
                </button>
              </div>

              {toggleVaccinationStatus && (
                <div className={styles.vaccineItem}>
                  <VaccineStatus className={styles.vaccinated} />
                </div>
              )}
            </div>

            {(this.state.activeTab === 0 && !this.isVaccinated()) && (
              <>
                <div className={styles.item}>
                  {country.inboundTestingTitle && (
                    <span className={styles.title}>
                      {country.inboundTestingTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="test" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.inboundTestingText && (
                        <span className={styles.subtitle}>
                          {country.inboundTestingText}
                        </span>
                      )}

                      {country.inboundTestingLink && (
                        <Link href={country.inboundTestingLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.item}>
                  {country.inboundQuarantineTitle && (
                    <span className={styles.title}>
                      {country.inboundQuarantineTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="quarantine" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.inboundQuarantineText && (
                        <span className={styles.subtitle}>
                          {country.inboundQuarantineText}
                        </span>
                      )}

                      {country.inboundQuarantineLink && (
                        <Link href={country.inboundQuarantineLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {(this.state.activeTab === 0 && this.isVaccinated()) && (
              <>
                <div className={styles.item}>
                  {country.vaccinatedTestingTitle && (
                    <span className={styles.title}>
                      {country.vaccinatedTestingTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="test" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.vaccinatedTestingText && (
                        <span className={styles.subtitle}>
                          {country.vaccinatedTestingText}
                        </span>
                      )}

                      {country.vaccinatedTestingLink && (
                        <Link href={country.vaccinatedTestingLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.item}>
                  {country.vaccinatedQuarantineTitle && (
                    <span className={styles.title}>
                      {country.vaccinatedQuarantineTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="quarantine" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.vaccinatedQuarantineText && (
                        <span className={styles.subtitle}>
                          {country.vaccinatedQuarantineText}
                        </span>
                      )}

                      {country.vaccinatedQuarantineLink && (
                        <Link href={country.vaccinatedQuarantineLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {(this.state.activeTab === 1 && !this.isVaccinated()) && (
              <>
                <div className={styles.item}>
                  {country.outboundTestingTitle && (
                    <span className={styles.title}>
                      {country.outboundTestingTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="test" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.outboundTestingText && (
                        <span className={styles.subtitle}>
                          {country.outboundTestingText}
                        </span>
                      )}

                      {country.outboundTestingLink && (
                        <Link href={country.outboundTestingLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.item}>
                  {country.outboundQuarantineTitle && (
                    <span className={styles.title}>
                      {country.outboundQuarantineTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="quarantine" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {country.outboundQuarantineText && (
                        <span className={styles.subtitle}>
                          {country.outboundQuarantineText}
                        </span>
                      )}

                      {country.outboundQuarantineLink && (
                        <Link href={country.outboundQuarantineLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {(this.state.activeTab === 1 && this.isVaccinated()) && (
              <>
                <div className={styles.item}>
                  {vaccinationRules.outboundTestingTitle && (
                    <span className={styles.title}>
                      {vaccinationRules.outboundTestingTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="test" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {vaccinationRules.outboundTestingText && (
                        <span className={styles.subtitle}>
                          {vaccinationRules.outboundTestingText}
                        </span>
                      )}

                      {vaccinationRules.outboundTestingLink && (
                        <Link href={vaccinationRules.outboundTestingLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.item}>
                  {vaccinationRules.outboundQuarantineTitle && (
                    <span className={styles.title}>
                      {vaccinationRules.outboundQuarantineTitle}
                    </span>
                  )}

                  <div className={styles.row}>
                    <span aria-hidden="true" className={styles.icon}>
                      <span className={classNames(styles.sprite, 'icon')} aria-hidden="true">
                        <Sprite name="quarantine" />
                      </span>
                    </span>

                    <div className={styles.content}>
                      {vaccinationRules.outboundQuarantineText && (
                        <span className={styles.subtitle}>
                          {vaccinationRules.outboundQuarantineText}
                        </span>
                      )}

                      {vaccinationRules.outboundQuarantineLink && (
                        <Link href={vaccinationRules.outboundQuarantineLink} className={styles.link}>
                          Find out more

                          <span className={classNames('icon', styles.externalIcon)} aria-hidden="true">
                            <Sprite name="external" />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={classNames(styles.price)}>
            <span className={styles.title}>
              Find flights
            </span>

            {(flights && flights.length > 0) && (
              <div className={styles.buttons}>
                {flights.map((flight, index) => (
                  <Link
                    aria-label={flight.title}
                    key={`flight-${index}`}
                    href={flight.link}
                    track={{
                      action: 'flight_booking_intension',
                      category: 'engagement',
                      label: convertToTitleCase(flight.title)
                    }}
                    fbTrack={{
                      event: 'trackCustom',
                      name: 'FlightBookingIntension'
                    }}
                    className={classNames(
                      styles.button,
                      buttonStyles.button
                    )}
                  >
                    <img
                      src={flight.logo.url}
                      alt={flight.title}
                      className={styles.logo}
                    />

                    <span className={styles.arrow}>
                      <Sprite name="chevron" fill={true} />
                    </span>
                  </Link>
                ))}
              </div>
            )}

            {flights && flights.length === 0 && (
              <span className={styles.fallback}>
                No flight information available.
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Info.propTypes = {
  alertLevel: PropTypes.string,
  country: PropTypes.object,
  vaccinationRules: PropTypes.object,
  flights: PropTypes.array,
  pricePrefix: PropTypes.string,
  price: PropTypes.string,
  apiPrice: PropTypes.string,
  priceSuffix: PropTypes.string,
  url: PropTypes.string,
  vaccinated: PropTypes.bool,
  className: PropTypes.string
}

const mapStateToProps = (state) => ({
  vaccinated: state.global.vaccinated
})

export default connect(mapStateToProps)(Info)
