import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Faqs from '~components/Faqs'
import styles from '~styles/components/faqAccordions.module.scss'

export default class FaqAccordions extends Component {
  constructor () {
    super()

    this.state = {
      activeItem: 0
    }
  }

  onClick (id, e) {
    e.preventDefault()

    let activeItem = id

    if (this.state.activeItem === activeItem) {
      activeItem = null
    }

    this.setState({ activeItem })
  }

  render () {
    return (
      <div className={styles.wrapper}>
        <div className={styles.items}>
          {this.props.accordions && (
            this.props.accordions.map((accordion, index) => (
              <Faqs
                key={index}
                title={accordion.title}
                items={accordion.items}
                active={this.state.activeItem === index}
                onClick={(e) => this.onClick(index, e)}
                className={styles.item}
              />
            ))
          )}
        </div>
      </div>
    )
  }
}

FaqAccordions.propTypes = {
  accordions: PropTypes.array
}
