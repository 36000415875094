import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TestCentre from '~components/sidebar/TestCentre'
import Cases from '~components/sidebar/Cases'
import Info from '~components/sidebar/Info'
import Signup from '~components/sidebar/Signup'
import classNames from 'classnames'
import styles from '~styles/components/sidebar.module.scss'

export default class Sidebar extends Component {
  render () {
    return (
      <div className={styles.sidebar}>
        <Info
          country={this.props.country}
          vaccinationRules={this.props.vaccinationRules}
          className={classNames(styles.panelTop)}
          url={this.props.url}
          flights={this.props.flights}
          alertLevel={this.props.alertLevel}
        />

        {(this.props.bookTestLink || this.props.bookTestText) && (
          <TestCentre
            text={this.props.bookTestText}
            link={this.props.bookTestLink}
            destination={this.props.destination}
            className={styles.panel}
          />
        )}

        <Cases
          className={styles.panel}
          countryCode={this.props.country.countryCode}
        />

        <Signup
          className={classNames(styles.panel)}
        />
      </div>
    )
  }
}

Sidebar.propTypes = {
  destination: PropTypes.string,
  alertLevel: PropTypes.string,
  country: PropTypes.object,
  vaccinationRules: PropTypes.object,
  flights: PropTypes.array,
  url: PropTypes.string,
  bookTestText: PropTypes.string,
  bookTestLink: PropTypes.string
}
