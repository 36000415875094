import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import { connect } from 'react-redux'
import { toggleModal } from '~redux/actions/global'
import RichText from '~components/RichText'
import Sprite from '~components/Sprite'
import Button from '~components/Button'
import styles from '~styles/components/panel.module.scss'
import buttonStyles from '~styles/components/button.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

class Signup extends Component {
  render () {
    return (
      <StaticQuery
        query={query} render={({ signup }) => (
          <div className={classNames(styles.wrapper, this.props.className)}>
            <span className={styles.title}>
              {signup.title}
            </span>

            <div className={styles.row}>
              <span aria-hidden="true" className={styles.icon}>
                <span className={styles.sprite}>
                  <Sprite name="mail" />
                </span>
              </span>

              {signup.text && (
                <div className={styles.content}>
                  <div className={classNames(editorStyles.editor, styles.text)}>
                    <RichText json={signup.text.json} />
                  </div>
                </div>
              )}
            </div>

            <Button
              className={classNames(styles.button, buttonStyles.white)}
              onClick={() => this.props.toggleModal(true)}
              track={{
                action: 'sign_up_intension',
                category: 'engagement',
                label: 'Covid guide'
              }}
              fbTrack={{
                event: 'trackCustom',
                name: 'CompleteRegistrationIntension'
              }}
            >
              Sign up
            </Button>
          </div>
        )}
      />
    )
  }
}

Signup.propTypes = {
  toggleModal: PropTypes.func,
  className: PropTypes.string
}

export const query = graphql`
  query {
    signup: contentfulSignup(slug: {eq: "signup"}) {
      id
      title
      text {
        json
      }
    }
  }
`

export default connect(null, { toggleModal })(Signup)
