import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Link from '~components/Link'
import styles from '~styles/components/panel.module.scss'
import buttonStyles from '~styles/components/button.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

const TestCentre = (props) => {
  return (
    <div className={classNames(styles.wrapper, props.className)}>
      <span className={styles.title}>
        Testing centre
      </span>

      <div className={styles.row}>
        <span aria-hidden="true" className={styles.icon}>
          <span className={styles.sprite}>
            <Sprite name="test" />
          </span>
        </span>

        <div className={styles.content}>
          <span className={styles.subtitle}>
            Book a test
          </span>

          {props.text && (
            <div className={classNames(editorStyles.editor, styles.text)}>
              <p>
                {props.text}
              </p>
            </div>
          )}
        </div>
      </div>

      {props.link && (
        <Link
          href={props.link}
          text="Book now"
          className={classNames(styles.button, buttonStyles.button)}
        />
      )}
    </div>
  )
}

TestCentre.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  destination: PropTypes.string,
  className: PropTypes.string
}

export default TestCentre
